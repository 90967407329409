<template>
  <div>
    <a-select
      v-model:value="plan"
      :placeholder="$t('Plan')"
      :filter-option="false"
      :size="size"
      style="width: 100%"
      show-search
      @change="handleChange"
    >
      <a-select-option v-for="p in plans" :key="p.planId" :value="p.planId">
        {{ p.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<i18n>
{
  "en": {
    "Plan": "Plan",
  },
  "ja": {
    "Plan": "プラン",
  }
}
</i18n>

<script>

export default {
  name: 'InputRole',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    plans: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      plan: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.plan = nv;
      },
    },
    plans: {
      immediate: true,
      handler(nv) {
        if (nv && nv.length > 0) {
          const exist = nv.find((p) => p.planId.toString() === this.plan);

          if (!exist) {
            this.plan = undefined;
          }
        }
      },
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.plan);
      this.$emit('update:value', this.plan);
    },
  },
};
</script>

<style scoped></style>
