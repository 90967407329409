<template>
  <div>
    <a-select
      v-model:value="vendor"
      show-search
      style="width: 100%"
      :placeholder="$t('externalVendorPlaceHolder')"
      dropdown-class-name="property-max-content-width"
      :default-active-first-option="false"
      :filter-option="false"
      :not-found-content="null"
      :size="size"
      @search="handleSearch"
      @change="handleChange"
    >
      <a-select-option v-for="v in vendors" :key="v.code" :value="v.code">
        {{ v.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<i18n>
  {
    "en": {
      "externalVendorPlaceHolder": "Select a Vendor"
    },
    "ja": {
      "externalVendorPlaceHolder": "Select a Vendor"
    }
  }
</i18n>

<script>
export default {
  name: 'InpuVendor',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      vendors: undefined,
      oVendors: undefined,
      vendor: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.vendor = value;
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    async handleSearch(value = '') {
      if (!this.oVendors?.length) {
        const vendors = await this.$store.dispatch('iscm-price-auto-reflection/listExternalVendors');
        this.oVendors = vendors;
        if (vendors?.length === 1) {
          this.handleChange(vendors[0].code);
        }
      }

      this.vendors = this.oVendors.filter((vendor) => vendor.name.match(value));
    },

    handleChange(value) {
      this.vendor = value;
      this.$emit('change', this.vendor);
      this.$emit('update:value', this.vendor);
    },
  },
};
</script>

<style>
.property-max-content-width {
  min-width: 300px !important;
}

@media screen and (max-width: 320px) {
  .property-max-content-width {
    min-width: auto !important;
  }
}
</style>
