<template>
  <div class="columns form is-mini-gap is-multiline">
    <div :class="'column ' + columnSize">
      <InputVendor
        v-model:value="vendor"
        class="rate-filter-input"
      />
    </div>
    <div :class="'column ' + columnSize">
      <InputProperty
        v-model:value="propertyId"
        :vendor="vendor"
        class="rate-filter-input"
      />
    </div>
    <div :class="'column ' + columnSize">
      <InputRoom
        v-model:value="roomCode"
        class="rate-filter-input"
        :property-id="propertyId"
        :vendor="vendor"
        :disabled="!propertyId"
        @change="handleChangeRoom"
      />
    </div>
    <div :class="'column ' + columnSize">
      <InputPlan
        v-model:value="plan"
        class="rate-filter-input"
        :plans="plans"
        :room-code="roomCode"
        :disabled="!roomCode"
      />
    </div>
    <div :class="'column ' + columnSize">
      <a-range-picker
        v-model:value="range"
        style="width: 100%"
        :size="size"
        :format="dateFormat"
      >
        <a-input :value="rangeString" :placeholder="$t('Request Date')" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </div>
    <div :class="'column ' + columnSize">
      <div class="columns">
        <div class="column is-12">
          <a-button
            v-show="vendor && roomCode && propertyId && plan"
            :size="size"
            style="min-width: 110px"
            type="primary"
            class="mr-3"
            @click="handleFilter"
          >
            {{ $t('Search') }}
          </a-button>
          <a-button
            v-show="roomCode || propertyId || plan || range.length"
            :size="size"
            style="min-width: 110px"
            type="default"
            @click="handleClear"
          >
            {{ $t('Clear') }}
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "date range": "date range",
    "Search": "Search",
    "Clear": "Clear",
    "Request Date" : "Request Date",
  },
  "ja": {
    "date range": "日付範囲",
    "Search": "サーチ",
    "Clear": "クリア",
    "Request Date" : "リクエスト日",
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputProperty from './InputProperty';
import InputRoom from './InputRoom';
import InputVendor from './InputVendor';
import InputPlan from './InputPlan';

import { cleanObject } from '@/utils/util';

export default {
  name: 'RatesFilter',
  components: {
    InputProperty,
    InputRoom,
    InputPlan,
    InputVendor,
    CalendarFilled,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
    steps: {
      type: Number,
      default: 10,
    },
    columnSize: {
      type: String,
      default: 'is-12',
    },
  },
  emits: ['filter', 'clear-filter'],
  data() {
    return {
      range: [],
      propertyId: undefined,
      roomCode: undefined,
      roomType: undefined,
      roomId: undefined,
      plan: undefined,
      vendor: undefined,
      plans: [],
    };
  },
  computed: {
    rangeString() {
      if (this.range.length === 0) {
        return null;
      }

      const from = this.$filters.date(this.range[0], { format: this.dateFormat });
      const to = this.$filters.date(this.range[1], { format: this.dateFormat });

      return `${from} ~ ${to}`;
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      const range = { dateFrom: undefined, dateTo: undefined };

      if (this.range.length) {
        range.dateFrom = this.$filters.date(this.range[0], { format: dateFormat });
        range.dateTo = this.$filters.date(this.range[1], { format: dateFormat });
      }

      return range;
    },
  },
  mounted() {
    this.vendor = this.$route.query.vendor || undefined;
    this.propertyId = this.$route.query.propertyId || undefined;
    this.roomCode = this.$route.query.roomCode || undefined;

    this.plan = this.$route.query.plan || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo]
      : [];
  },
  methods: {
    async handleFilter() {
      if (!this.rangeString) {
        this.setDefaultDateRange();
      }

      const query = cleanObject({
        ...this.$route.query,
        roomCode: this.roomCode,
        roomType: this.roomType,
        roomId: this.roomId,
        propertyId: this.propertyId,
        plan: this.plan,
        vendor: this.vendor,
        ...this.formattedRange,
      });

      await this.$router.push({ query });
      this.$emit('filter');
    },
    setDefaultDateRange() {
      const now = dayjs().format(this.dateFormat);
      const plusTwelveDays = dayjs().add(this.steps + 2, 'days').format(this.dateFormat);

      this.range = [now, plusTwelveDays];
    },
    async handleClear() {
      this.propertyId = undefined;
      this.roomCode = undefined;
      this.roomType = undefined;
      this.roomId = undefined;
      this.plan = undefined;
      this.vendor = undefined;
      this.range = [];

      await this.$router.push({ query: {} });

      this.$emit('clear-filter');
    },
    handleChangeRoom(_roomCode, plans) {
      this.plans = plans;
    },
  },
};
</script>

<style lang="scss" scoped>
.is-12::v-deep .rate-filter-input {
  min-width: 150px;
}
</style>
