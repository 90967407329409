<template>
  <a-select
    v-model:value="roomCode"
    show-search
    :placeholder="$t('Select room')"
    style="width: 100%"
    dropdown-class-name="room-max-content-width"
    :default-active-first-option="false"
    :not-found-content="null"
    :size="size"
    :filter-option="false"
    @search="handleSearch"
    @change="handleChange"
  >
    <a-select-option
      v-for="r in rooms"
      :key="r.roomCode"
      :value="r.roomCode"
      :data="r.plans"
    >
      {{ r.name }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "Select room": "Select room"
  },
  "ja": {
    "Select room": "部屋選択"
  }
}
</i18n>

<script>

export default {
  name: 'InputRoom',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    propertyId: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    vendor: {
      type: String,
      default() {
        return '';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      roomCode: undefined,
      rooms: undefined,
      oRooms: undefined,
      type: 'PlanRateConditionPerRoom',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.roomCode = nv;
      },
    },
    propertyId: {
      immediate: true,
      async handler(nv) {
        this.oRooms = [];
        this.rooms = [];
        if (nv != null) {
          await this.handleSearch();

          const room = this.oRooms.find((room) => room.roomCode === this.roomCode);

          if (room) {
            this.handleChange(room.roomCode, { data: room.plans });
          }
        }
      },
    },
  },
  mounted() {
    if (this.$route.name.match(/per-person$/)) {
      this.type = 'PlanRateConditionPerPerson';
    }
  },
  created() {
  },
  methods: {
    async handleSearch(value = '') {
      try {
        if (!this.oRooms?.length) {
          const { rooms } = await this.$store.dispatch(
            'iscm-properties/listRoom',
            { hotelNo: this.propertyId, type: this.type, vendor: this.vendor },
          );
          this.oRooms = rooms;
          if (this.oRooms.every((room) => room.roomCode !== this.roomCode)) {
            this.handleChange(null, {});
          }
        }
        this.rooms = this.oRooms.filter((room) => room.name.match(value));
      } catch (error) {
        this.$router.replace({ query: null });
        this.handleChange(null, {});
      }
    },

    handleChange(value, option) {
      this.roomCode = value;
      this.$emit('change', this.roomCode, option?.data);
      this.$emit('update:value', this.roomCode);
    },
  },
};
</script>

<style>
.room-max-content-width {
  min-width: 250px !important;
}
@media screen and (max-width: 380px) {
  .room-max-content-width {
    min-width: auto !important;
  }
}
</style>
