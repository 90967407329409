<template>
  <a-select
    v-model:value="property"
    show-search
    :placeholder="$t('Select property')"
    style="width: 100%"
    dropdown-class-name="property-max-content-width"
    :filter-option="false"
    :not-found-content="null"
    :size="size"
    @search="handleSearch"
    @change="handleChange"
  >
    <a-select-option
      v-for="p in properties"
      :key="p.name"
      :value="p.hotelNo"
    >
      {{ p.name }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "Select property": "Select property"
  },
  "ja": {
    "Select property": "物件選択"
  }
}
</i18n>

<script>
export default {
  name: 'InputProperty',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    vendor: {
      type: String,
      default: null,
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      id: undefined,
      properties: undefined,
      oProperties: undefined,
      property: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.property = nv;
        if (nv == null && this.oProperties?.length === 1) {
          this.handleChange(this.oProperties[0].hotelNo);
        }
      },
    },
    vendor: {
      immediate: true,
      handler() {
        this.handleSearch();
      },
    },
  },
  methods: {
    async handleSearch(value = '') {
      if (!this.vendor) return;
      if (!this.oProperties?.length) {
        const { properties } = await this.$store.dispatch('iscm-properties/list', { vendor: this.vendor });
        this.oProperties = properties;
        if (properties?.length === 1) {
          this.handleChange(properties[0].hotelNo);
        }
      }

      this.properties = this.oProperties.filter((property) => property.name.match(value));
    },
    handleChange(value) {
      this.property = value;
      const selected = this.oProperties.find((p) => p.hotelNo === value);

      this.$emit('change', selected);
      this.$emit('update:value', this.property);
    },
  },
};
</script>

<style>
.property-max-content-width {
  min-width: 300px !important;
}
@media screen and (max-width: 320px) {
  .property-max-content-width {
    min-width: auto !important;
  }
}
</style>
