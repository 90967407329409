<template>
  <div>
    <div v-if="step === 1" class="columns is-multiline">
      <div class="column is-12">
        <h3 class="has-text-primary has-text-weight-bold is-size-4">
          <i class="el-icon-warning has-text-danger" /> {{ $t('Confirm') }}
        </h3>
      </div>
      <div class="column is-12">
        <p> {{ $t('description', { vendor: vendor.name }) }} </p>
      </div>
      <div class="column has-text-right is-12">
        <a-button
          class="m-r-2x"
          style="min-width: 125px"
          :disabled="loading"
          @click="handleCancel"
        >
          {{ $t('Cancel') }}
        </a-button>
        <a-button
          type="primary"
          style="min-width: 125px"
          :loading="loading"
          @click="handleConfirm"
        >
          {{ $t('Confirm') }}
        </a-button>
      </div>
    </div>
    <div v-if="step === 2" class="columns is-multiline">
      <div class="column is-12">
        <h3 class="has-text-secondary has-text-weight-bold is-size-4">
          {{ $t('Success') }}
        </h3>
      </div>
      <div class="column is-12">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="$t('success-message')" />
      </div>
      <div class="column has-text-right is-12">
        <a-button
          class="button-secondary-outline"
          style="min-width: 125px"
          @click="handleConfirmed"
        >
          {{ $t('Ok') }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<!-- eslint-disable -->
<i18n>
{
  "en": {
    "description": "Are you sure you want to submit the rates to {vendor}?",
    "Confirm": "Confirm",
    "Cancel": "Cancel",
    "Success": "Success",
    "success-message": "Rates have been submitted to {vendor}.",
    "Ok": "Ok",
  },
  "ja": {
    "description": "この料金をねっぱんに送信します。よろしいですか？",
    "Confirm": "承認",
    "Cancel": "取り消し",
    "Success": "料金更新",
    "success-message": "料金を外部に送信しました。",
    "Ok": "Ok",
  }
}
</i18n>
<!-- eslint-enable -->

<script>
export default {
  name: 'SubmitExternalConfirm',
  components: {},
  props: {
    rates: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    vendor: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    type: {
      required: true,
      type: String,
      default: () => 'PerRoom',
    },
  },
  emits: ['cancel', 'confirmed'],
  data() {
    return {
      step: 1,
      loading: false,
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleConfirmed() {
      this.$emit('confirmed');
    },
    async handleConfirm() {
      try {
        if (this.loading) return;

        this.loading = true;
        const { success } = await this.$store.dispatch(`iscm-external/updateRate${this.type}`, this.rates);
        if (success) {
          this.$message.success(this.$t('success-message', { vendor: this.vendor.name }));
          this.loading = false;
          this.step = 2;
        }
      } catch (error) {
        this.loading = false;
        this.$message.error(`Submit to ${this.vendor.name} unsuccessful`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rate-save-btn {
  margin-left: 0.8rem;
  margin-bottom: 0.8rem;
  min-width: 115px;
}
</style>
